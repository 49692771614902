import React from "react";
import styled from "@emotion/styled/macro";

import YouTubePlayer from "react-player";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
  margin: 40px auto;
`;

const PlayerWrapper = styled.div`
  position: relative;
  padding-top: 56.25%;
  width: 100%;
  border: 3px solid #d9b779;
`;

const Over = () => (
  <Container>
    <PlayerWrapper>
      <YouTubePlayer
        url="https://www.youtube.com/watch?v=ZazU1gg_JqE?VQ=HD1080"
        className="react-player"
        controls
        width="100%"
        height="100%"
      />
    </PlayerWrapper>
  </Container>
);

export default Over;
