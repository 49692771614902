import React, { createContext, useContext, useState, useEffect } from "react";

export const AuthenticationContext = createContext(null);

const AuthenticationProvider = (props) => {
  const [authenticated, setAuthenticated] = useState(null);

  const checkToken = async () => {
    const result = await fetch("/api/checkToken");
    setAuthenticated(result.status === 200 ? true : false);
  };

  useEffect(() => {
    checkToken();
  }, []);

  const onLogin = async (data) => {
    const response = await fetch("/api/login", {
      method: "POST",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
      },
      referrerPolicy: "no-referrer",
      body: JSON.stringify(data),
    });

    response.status === 200 ? setAuthenticated(true) : setAuthenticated(false);
    return response;
  };

  const onLogout = async () => {
    const response = await fetch("/api/logout");
    if (response.status === 200) {
      setAuthenticated(false);
    }
    return response;
  };

  return (
    authenticated !== null && (
      <AuthenticationContext.Provider
        value={{ authenticated, onLogin, onLogout }}
      >
        {props.children}
      </AuthenticationContext.Provider>
    )
  );
};

export const useAuthenticationContext = () => useContext(AuthenticationContext);

export default AuthenticationProvider;
