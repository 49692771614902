import React from "react";
import { NavLink } from "react-router-dom";
import styled from "@emotion/styled/macro";

import { CATEGORIES } from "../constants";

const Container = styled.div`
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 20px;
  align-items: center;
  text-align: center;
`;

const Navigation = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media screen and (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    width: 700px;
  }
`;

const Link = styled(NavLink)`
  margin-top: 16px;
  text-decoration: none;
  color: #73466b;
  background: white;
  border-bottom: 1px solid #d9b779;
  height: 18px;
  padding: 8px 14px;
  text-align: center;
  cursor: pointer;
  white-space: nowrap;
  width: 160px;
  font-weight: 700;
`;

const Img = styled.img`
  width: 250px;
`;

const Header = () => (
  <Container>
    <NavLink
      activeStyle={{
        marginBottom: "2px",
      }}
      to="/"
    >
      <Img src="/logo.png"></Img>
    </NavLink>

    <Navigation>
      {CATEGORIES.map((category, i) => (
        <Link
          activeStyle={{
            borderBottom: "3px solid #d9b779 ",
          }}
          key={i}
          to={category.key}
        >
          {category.label}
        </Link>
      ))}
    </Navigation>
  </Container>
);

export default Header;
