import React, { Suspense, lazy } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import styled from "@emotion/styled/macro";
import DotLoader from "react-spinners/DotLoader";

import Home from "./Pages/Home";
import { Header, Footer } from "./Sections";
import { Gallery, PrivateRoute } from "./Components";
import { CATEGORIES } from "./constants";

const Login = lazy(() => import("./Pages/Login"));
const Admin = lazy(() => import("./Pages/Admin"));

const Container = styled.div`
  display: flex;
  min-height: 95vh;
  flex-direction: column;
  align-items: stretch;
  margin: 20px auto;
`;

const Content = styled.div`
  flex-grow: 1;
  background: white;
`;

const Loader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 400px;
`;

const App = () => (
  <BrowserRouter>
    <Suspense
      fallback={
        <Loader>
          <DotLoader size={80} color={"#73466b"} loading={true} />
        </Loader>
      }
    >
      <Container>
        <Header />
        <Content>
          <Switch>
            <Route exact path="/" component={Home} />} />
            {CATEGORIES.map((category, i) => (
              <Route
                key={i}
                exact
                path={`/${category.key}`}
                render={(props) => (
                  <Gallery {...props} category={category.key} />
                )}
              />
            ))}
            <Route path="/login" component={Login} />
            <PrivateRoute path="/admin" component={Admin} />
          </Switch>
        </Content>
        <Footer />
      </Container>
    </Suspense>
  </BrowserRouter>
);

export default App;
