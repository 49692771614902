import React, { useEffect, useState } from "react";
import styled from "@emotion/styled/macro";

import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";

const Container = styled.div`
  margin: 0 20px;
`;

function PhotoGallery({ category = null }) {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);
  const [media, setMedia] = useState([]);

  useEffect(() => {
    fetch(`/api/media${category ? `?category=${category}` : ""}`)
      .then((response) => response.json())
      .then((result) => {
        setMedia(
          result.map((item) => ({
            ...item,
            src: `media/previews/${item.id}.jpg`,
          }))
        );
      });
  }, [category]);

  useEffect(() => {
    if (viewerIsOpen) {
      setTimeout(() => {
        document.querySelector(".react-images__pager").style.display = "flex";
        document.querySelector(".react-images__pager").style["align-items"] =
          "center";
        document.querySelector(".react-images__pager").style[
          "justify-content"
        ] = "center";
      }, 0);
    }
  }, [viewerIsOpen]);

  const openLightbox = (event, obj) => {
    setCurrentImage(obj.index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  return (
    <Container>
      <Gallery photos={media} onClick={openLightbox} />
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={media.map((image) => ({
                ...image,
                src: `media/images/${image.id}.jpg`,
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </Container>
  );
}

export default PhotoGallery;
