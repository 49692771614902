const CATEGORIES = [
  {
    key: "marchagrandi",
    label: "Marcha Grandi",
  },
  {
    key: "sweetsixty",
    label: "Sweet Sixty",
  },
  {
    key: "marchadidespedida",
    label: "Marcha Di Despedida",
  },
];

const CATEGORY_LABELS = {
  marchagrandi: "Marcha Grandi",
  sweetsixty: "Sweet Sixty",
  marchadidespedida: "Marcha Di Despedida",
};

export { CATEGORY_LABELS, CATEGORIES };
