import React from "react";
import styled from "@emotion/styled/macro";

import { useAuthenticationContext } from "../Authentication";

const Container = styled.div`
  flex-shrink: 0;
  display: flex;
  margin: 10px auto;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-top: 2px solid #d9b779;
  width: 300px;
`;

const Text = styled.div`
  margin-top: 30px;
  color: #73466b;
`;

const Logout = styled.div`
  margin-top: 10px;
  margin-bottom: 8px;
  text-decoration: none;
  color: #d9b779;
  border-bottom: 1px solid #d9b779;
  height: 18px;
  padding: 8px 14px;
  text-align: center;
  cursor: pointer;
  white-space: nowrap;
  width: 80px;
`;

const Footer = () => {
  const { authenticated, onLogout } = useAuthenticationContext();

  return (
    <Container>
      <Text>Made with ♥ by Yoni Stap</Text>
      <Text>Copyright © 2024 sharine.nl</Text>
      {authenticated && <Logout onClick={onLogout}>Uitloggen</Logout>}
    </Container>
  );
};

export default Footer;
